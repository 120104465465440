import React from "react";
import RsvpForm from "../components/RsvpForm";
import { useTranslation } from "react-i18next";

export default function RSVP() {
  const { t } = useTranslation();
  return (
    <>
      <div className="mt-20 md:text-lg text-5 text-sm font-bold text-center">
        {t("rsvp-family")}
      </div>
      <section className="md:flex justify-center p-5">
        <div className="animate-wiggle">
          <img src="/images/date2.png" alt="date" className="w-full" />
        </div>

        <div className="ms-10 text-center p-5 md:w-1/3 mt-3">
          <div className="text-4xl font-light my-2 mt-16 text-3">
            When & Where
          </div>
          <div className="pt-2">January 13, 2024</div>
          <div className="pt-2">6:30pm</div>
          <div className="pt-2">Hilton Anatole</div>
          <div className="pt-2">
            2201 N Stemmons Fwy Floor 27,
            <br />
            Dallas, TX, 75207
          </div>
        </div>
      </section>
      <section>
        <div className="lg:p-24 md:p-16 p-3">
          <iframe
            className="w-full md:px-40 h-96"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13414.85345458184!2d-96.83944814243567!3d32.79985871519814!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864e99529345015b%3A0x841a9bf37302f7e2!2sHilton%20Anatole!5e0!3m2!1sen!2sus!4v1702518602145!5m2!1sen!2sus"
            allowfullscreen="yes"
            loading="lazy"
          ></iframe>
          ;
        </div>
      </section>
      <RsvpForm />
    </>
  );
}
